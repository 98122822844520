import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as EmailValidator from 'email-validator';

// components
import FormGroup from 'components/common/form-group';
import Button from 'components/common/button';

// styles
import 'stylesheets/contact-us/index.scss';

// component class
class ContactUsForm extends Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    sendError: PropTypes.string,
    isSendInProgress: PropTypes.bool,
    description: PropTypes.string,
    buttonText: PropTypes.string,
  };

  state = {
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    subject: '',
    message: '',
    errors: {},
  };

  validateFormDataInState() {
    const {
      firstName,
      lastName,
      email,
      subject,
      message,
      errors: preErrors,
    } = this.state;

    const errors = { ...preErrors };
    let isValid = true;

    if (!firstName) {
      errors.firstName = 'Your first name must be set';
      isValid = false;
    }

    if (!lastName) {
      errors.lastName = 'Your last name must be set';
      isValid = false;
    }

    if (!email) {
      errors.email = 'Email must be set';
      isValid = false;
    } else if (!EmailValidator.validate(email)) {
      errors.email = 'Email is invalid';
      isValid = false;
    }

    if (!subject) {
      errors.subject = 'Subject text must be set';
      isValid = false;
    }

    if (!message) {
      errors.message = 'Message text must be set';
      isValid = false;
    }

    this.setState({ errors });

    return isValid;
  }

  handleInputChange = e => {
    const { errors: preErrors } = this.state;
    const { name, value } = e.target;

    const errors = {
      ...preErrors,
      [name]: null,
    };

    this.setState({ [name]: value, errors });
  };

  handleSubmitForm = e => {
    e.preventDefault();
    e.stopPropagation();

    const { onSubmit } = this.props;

    const isValid = this.validateFormDataInState();

    if (!isValid) return null;

    const { firstName, lastName, phone, email, subject, message } = this.state;

    onSubmit({
      firstName,
      lastName,
      phone,
      email,
      subject,
      message,
    });
  };

  render() {
    const { sendError, isSendInProgress, description, buttonText } = this.props;
    const {
      firstName,
      lastName,
      phone,
      email,
      subject,
      message,
      errors,
    } = this.state;

    return (
      <form onSubmit={this.handleSubmitForm} className="d-flex flex-column">
        <div
          className="contact-us_description d-none d-sm-block"
          dangerouslySetInnerHTML={{ __html: description }}
        />

        <FormGroup
          name="firstName"
          label="First Name"
          placeholder="Enter your first name"
          value={firstName}
          errors={errors}
          onChange={this.handleInputChange}
        />

        <FormGroup
          name="lastName"
          label="Last Name"
          placeholder="Enter your last name"
          value={lastName}
          errors={errors}
          onChange={this.handleInputChange}
        />

        <FormGroup
          name="phone"
          label="Phone"
          placeholder="Enter your phone"
          value={phone}
          errors={errors}
          onChange={this.handleInputChange}
        />

        <FormGroup
          name="email"
          label="E-mail"
          placeholder="Enter your e-mail"
          value={email}
          errors={errors}
          onChange={this.handleInputChange}
        />
        <FormGroup
          name="subject"
          label="Subject"
          placeholder="Enter subject text here"
          value={subject}
          errors={errors}
          onChange={this.handleInputChange}
        />
        <FormGroup
          type="textarea"
          name="message"
          label="Message"
          placeholder="Type your text here"
          value={message}
          errors={errors}
          onChange={this.handleInputChange}
        />

        {!!sendError && <div>{sendError}</div>}

        <Button
          className="contact-us_button"
          type="submit"
          disabled={isSendInProgress}
          small
        >
          {buttonText}
        </Button>
      </form>
    );
  }
}

export default ContactUsForm;

import React, { Component } from 'react';
import { StaticQuery, graphql } from 'gatsby';

// components
import Header from 'components/common/header';
import Layout from 'components/common/layout';
import ContactUsForm from 'components/contact-us/contact-us-form';
import ImageAsBackground from 'components/common/image-as-background';

// lib
import ActiveCampaignTracking from 'lib/active-campaign-tracking';
import trackPageVisit from 'lib/track-page-visit';

// constants
import { CONTACT_US_SUBMIT_EVENT_NAME } from 'constants/active-campaign';

// styles
import 'stylesheets/contact-us/index.scss';

// component class
class ContactUsPage extends Component {
  promiseSendFormData = null;

  state = {
    sendError: null,
    isSendInProgress: false,
    isSuccessfulSend: false,
  };

  handleSubmitForm = async formData => {
    if (this.state.isSendInProgress) return null;

    this.setState({ isSendInProgress: true, sendError: null });

    this.promiseSendFormData = fetch(`/.netlify/functions/contact-us`, {
      method: 'POST',
      body: JSON.stringify(formData),
    });

    const result = await this.promiseSendFormData;
    const body = await result.json();

    if (!result.ok) {
      const sendError =
        body.error || 'Oops! Something wrong. Try to send later.';

      this.setState({
        isSendInProgress: false,
        isSuccessfulSend: false,
        sendError,
      });

      return null;
    }

    this.setState({
      isSendInProgress: false,
      isSuccessfulSend: true,
    });
  };

  render() {
    const { data } = this.props;
    const { isSendInProgress, isSuccessfulSend, sendError } = this.state;

    const node = (data.allContentfulContactUsPage.edges[0] || {}).node || {};

    const { title, buttonText } = node;
    const headerImage = (node.headerImage || {}).fluid;
    const mobileHeaderImage = (node.mobileHeaderImage || {}).fluid;
    const description = ((node.description || {}).childMarkdownRemark || {})
      .html;

    return (
      <Layout title="Contact us">
        <main>
          <div className="contact-us_top-section">
            <Header styleType="dark" showLogo />

            <ImageAsBackground
              image={headerImage}
              mobileImage={mobileHeaderImage}
            />

            <div className="contact-us_top-section_content">
              <div className="contact-us_top-section_title">{title}</div>
              <div
                className="contact-us_description d-sm-none"
                dangerouslySetInnerHTML={{ __html: description }}
              />
            </div>

            <div className="contact-us_top-section_overlay" />
          </div>

          <div className="contact-us_content">
            {isSuccessfulSend ? (
              <SuccessMessage />
            ) : (
              <ContactUsForm
                description={description}
                onSubmit={this.handleSubmitForm}
                sendError={sendError}
                isSendInProgress={isSendInProgress}
                buttonText={buttonText}
              />
            )}
          </div>
        </main>
      </Layout>
    );
  }
}

function SuccessMessage() {
  return (
    <div className="contact-us_success">
      <div className="contact-us_success_message">
        Thank you for reaching out, we will respond very shortly!
      </div>
    </div>
  );
}

export default trackPageVisit(props => (
  <StaticQuery
    query={graphql`
      query {
        allContentfulContactUsPage(
          sort: { fields: [publishDate], order: DESC }
          limit: 1
        ) {
          edges {
            node {
              title
              buttonText
              description {
                childMarkdownRemark {
                  html
                }
              }
              headerImage {
                fluid {
                  ...GatsbyContentfulFluid
                }
              }
              mobileHeaderImage {
                fluid {
                  ...GatsbyContentfulFluid
                }
              }
            }
          }
        }
      }
    `}
    render={data => <ContactUsPage data={data} {...props} />}
  />
));
